import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ServerService } from '../../services/server.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-driver',
  templateUrl: './driver.component.html',
  styleUrls: ['./driver.component.css']
})
export class DriverComponent implements OnInit {

   marital_status = ['married','unmarried'];
    joined_through = [{'id':1,'found_us':'TV Ad'},{'id':2,'found_us':'Radio Ad'},{'id':3,'found_us':'Missed Call'},{'id':4,'found_us':'Walk-in'},{'id':5,'found_us':'Website, App, Google'}];

        driverSignup: FormGroup;
        driverSignin: FormGroup;
        constructor(private serverService: ServerService, private router:Router) { }

    
        driversigninError = "";
        driversigninSuccess = "";
        driversignupError = "";
        driversignupSuccess = "";
    
        driversignupOtp: boolean = false;
        driversigninOtp: boolean = false;
        driversignupData: boolean = true;

        ngOnInit() {

            this.driverSignup = new FormGroup({
                'name' : new FormControl(null,Validators.required),
                'city' : new FormControl('1', Validators.required),
                'mobile_num' : new FormControl(null, Validators.required),
                'alt_mobile_number' : new FormControl(null),
                'emergency_contact_person' : new FormControl(null,Validators.required),
                'emergency_phone' : new FormControl(null,Validators.required),
                'referred_person' : new FormControl(null,Validators.required),
                'referrer_mobile' : new FormControl(null,Validators.required),
                'email_address' : new FormControl(null,Validators.required),
                'otp' : new FormControl(null),
                'marital_status' : new FormControl('unmarried'),
                'joined_through' : new FormControl(1),
                'otp_token' : new FormControl(null),
                'action' : new FormControl('generate_otp')
            });
            
            
            this.driverSignin = new FormGroup({
                
                'mobile_num' : new FormControl(null, Validators.required),
                'password' : new FormControl(null, Validators.required),
                'otp' : new FormControl(null),
                'otp_token' : new FormControl(null),
                'action' : new FormControl('drivers_login'), 
                'action_key' : new FormControl('generate_otp')
            });

        }
    
    driverSingup(){
             
            let driverCreate:any = ({"api_type":"application","operation":"create","access_token":"","moduleType":"drivers","element_data":this.driverSignup.value});
        
            if(this.driverSignup.value.action == "generate_otp"){
            
                    this.serverService.sendServer(driverCreate).then((response:any) => {
                    console.log(response);
                        if(response.status == true){
                            this.driverSignup.patchValue({
                                'action' : 'generate_driver',
                                'otp_token' : response.result.otp_token,
                            });

                             this.driversignupOtp = true;
                            
                            this.driversignupSuccess="OTP Sucessfully Sent";
                             this.driversignupError="";

                        }
                        else{
                            
                             this.driversignupSuccess = "";
                            this.driversignupError=response.error.message;

                        }
                    }, 
                    (error)=>{
                        console.log(error);
                    });

            }
            else if(this.driverSignup.value.action == "generate_driver"){

                this.serverService.sendServer(driverCreate).then((response:any) => {
  console.log(response);
                        if(response.status == true){

                         localStorage.setItem('access_driver_token', response.access_token);  
                             this.driversignupError="";
                             this.driversignupSuccess = "Driver created in successfully";
                            this.driverSignup.reset();
                            setTimeout(() => {this.router.navigate(['/driver-profile']); }, 3000);

                        }
                        else{

                             this.driversignupSuccess = "";
                            this.driversignupError=response.error.message;

                        }

                    }, 
                    (error)=>{
                        console.log(error);
                    });

            }
        
        
        
    }
    
    driverviewSignIn(){
        
        this.driversignupData = false;
    }
    
    driverviewSignUp(){
        
        this.driversignupData = true;
    }
    
    driverSingin(){
        
        
         let diverlogin:any = ({"api_type":"application","operation":"login","access_token":"","moduleType":"login","element_data":this.driverSignin.value});
            if(this.driverSignin.value.action_key == "generate_otp"){

                    this.serverService.sendServer(diverlogin).then((response:any) => {
                        if(response.status == true){
                            this.driverSignin.patchValue({
                                'action' : 'drivers_login',
                                'action_key' : 'validate_otp',
                                'otp_token' : response.result.otp_token,
                            });

                             this.driversigninOtp = true;
                              this.driversigninSuccess="OTP Sucessfully Sent";
                             this.driversigninError="";

                        }
                        else{
                             this.driversigninSuccess = "";
                            this.driversigninError=response.error.message;

                        }
                    }, 
                    (error)=>{
                        console.log(error);
                    });

            }
            else if(this.driverSignin.value.action_key == "validate_otp"){

                this.serverService.sendServer(diverlogin).then((response:any) => {
                      console.log(response);
                if(response.status == true){

                         localStorage.setItem('access_driver_token', response.access_token); 
                         this.router.navigate(['/driver-profile']); 
                            
                            this.driversigninError="";
                    
                            this.driversigninSuccess = "Driver created in successfully";
                            this.driverSignin.reset();
                            setTimeout(() => {this.router.navigate(['/driver-profile']); }, 3000);

                        }
                        else{
                            this.driversigninSuccess = "";
                            this.driversigninError=response.error.message;
                            

                        }
                    }, 
                    (error)=>{
                        console.log(error);
                    });

            }
        
        
    }


}
