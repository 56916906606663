import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServerService } from '../../services/server.service';
import Swal from 'sweetalert2/dist/sweetalert2.all.js';


@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
contactUs: FormGroup;
 accessToken:string;
 logginAs:string;
   constructor(private serverService: ServerService, private router:Router) { }

  ngOnInit() {
              this.contactUs = new FormGroup({
                'contact_name' : new FormControl(null),
                'email_id' : new FormControl(null),
                'message' : new FormControl(null),
                'contact_number' : new FormControl(null),
                
            });
        this.accessToken = localStorage.getItem('access_token');
        this.logginAs = localStorage.getItem('logged_in');
  }

    contactValidation(){
        let contactUs:any = this.contactUs.value;
         if(contactUs.contact_name == null){
            Swal.fire("Error", "Please enter the name", "error");
            return false;
         }
        if(contactUs.email_id == null){
            Swal.fire("Error", "Please enter the email", "error");
            return false;
         }
         if(contactUs.message == null){
            Swal.fire("Error", "Please enter the message", "error");
            return false;
         }
         if(contactUs.contact_number == null){
          Swal.fire("Error", "Please enter the phone number", "error");
          return false;
       }
        return true;
    }



sendContact(){

  let contactUs:any = this.contactUs.value;
var validation_data;
    validation_data = this.contactValidation();
        if (validation_data != true) {
            return false;
    }
    let req_data:any = ({"api_type":"application","operation":"contactform","contact_name":contactUs.contact_name,"email_id":contactUs.email_id,"message":contactUs.message});
      this.serverService.sendServer(req_data).then((response:any) => {
        if(response[0].result=="true"){
          Swal.fire(
            'Good job!',
            'Thank you for contacting us, we will get back to you shortly',
            'success'
          )
        }

        }, 
        (error)=>{
            console.log(error);
            Swal.fire(
            'sorry',
            'Please check the values',
            'error'
          )
        });
}
}
