import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServerService } from '../../services/server.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class CustomerProfileComponent implements OnInit {

 customer_name:string;
 mobile_number:string;
 city:string;
 email:string;
 address:string;
 accessToken:string;
  constructor(private serverService: ServerService, private router:Router) { }

  ngOnInit() {

  	this.accessToken = localStorage.getItem('access_token');
	  if(this.accessToken != ''){
		this.getCustomerInfo();
	  }
	  else{
	  	this.router.navigate(['/customers']);
	  }
  }

  getCustomerInfo(){

            let customerDetails:any = ({"api_type":"application","operation":"customer_view","access_token":this.accessToken,"moduleType":"customers","element_data":[]});

               this.serverService.sendServer(customerDetails).then((response:any) => {

                        if(response[0].result == "true"){
	                         	this.customer_name = response[0].customer_name;
	                         	this.mobile_number = response[0].mobile_num;
	                         	this.city = response[0].city_name;
	                         	this.address = response[0].address;
	                         	this.email = response[0].email_address;

                        }
                        else{
                            
                       
                        }
                    }, 
                    (error)=>{
                        console.log(error);
                    });


  }

}
