import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServerService } from '../../services/server.service';

@Component({
  selector: 'app-driver-profile',
  templateUrl: './driver-profile.component.html',
  styleUrls: ['./driver-profile.component.css']
})
export class DriverProfileComponent implements OnInit {
  accessToken:string;
  city_name:string;
  email_address:string;
  mobile_number:string;
  driver_name:string;
  address:string;
  constructor(private serverService: ServerService, private router:Router) { }

  ngOnInit() {

         this.accessToken = localStorage.getItem('access_driver_token');

  

		  if(this.accessToken != '' && this.accessToken != null){
			 this.getDriverInfo();
		  }
		  else{
		  	 this.router.navigate(['/drivers']);
		  }
  }


  getDriverInfo(){


      let driverDetails:any = ({"api_type":"application","operation":"view","access_token":this.accessToken,"moduleType":"drivers","element_data":[]});

               this.serverService.sendServer(driverDetails).then((response:any) => {

                        if(response.status == true){

	                        if(response.result != false){

	                         	console.log(response);

	                         this.driver_name = response.result.name;
                           this.mobile_number = response.result.mobile_num;
                           this.email_address = response.result.email_address;
                           this.city_name = response.result.city_name;
                           this.address = response.result.address;
	                        }

                        }
                        else{
                            
                       
                        }
                    }, 
                    (error)=>{
                        console.log(error);
                    });


  }

}
