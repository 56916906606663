import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServerService } from '../services/server.service';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.css']
})
export class CustomersComponent implements OnInit {
        customerSignup: FormGroup;
        customerSignin: FormGroup;
        accessToken:string;

        constructor(private serverService: ServerService, private router:Router) { }
        customersigninError = "";
        customersigninSuccess = "";
        customersignupError = "";
        customersignupSuccess = "";
        signupOtp: boolean = false;
        signinOtp: boolean = false;
        signupData: boolean = false;

        ngOnInit() {
              this.accessToken = localStorage.getItem('access_token');
              if(this.accessToken){
                this.router.navigate(['/customer-profile']);
              }

            this.customerSignup = new FormGroup({
                'customer_name' : new FormControl(null,Validators.required),
                'mobile_num' : new FormControl(null, Validators.required),
                'email_address' : new FormControl(null, [Validators.required, Validators.email]),
                'address' : new FormControl(null),
                'otp' : new FormControl(null),
                'otp_token' : new FormControl(null),
                'action' : new FormControl('generate_otp')
                
            });
            
            
            this.customerSignin = new FormGroup({
                'mobile_num' : new FormControl(null, Validators.required),
                'password' : new FormControl(null, Validators.required),
                'otp' : new FormControl(null),
                'otp_token' : new FormControl(null),
                'action' : new FormControl('customers_login'), 
                'action_key' : new FormControl('generate_otp')
            });

        }
    
    onCustomerSingup(){

            let customerCreate:any = ({"api_type":"application","operation":"customer_signup","access_token":"","moduleType":"customers","element_data":this.customerSignup.value});

            if(this.customerSignup.value.action == "generate_otp"){

                    this.serverService.sendServer(customerCreate).then((response:any) => {
     
                        if(response.result == "true"){
                            this.customerSignup.patchValue({
                                'action' : 'generate_customer',
                                'otp_token' : response.otp_token,
                            });

                             this.signupOtp = true;
                            
                            this.customersignupSuccess="OTP Sucessfully Sent";
                             this.customersignupError="";

                        }
                        else{
                            
                             this.customersignupSuccess = "";
                            this.customersignupError="Customer Already Registered With This Number";

                        }
                    }, 
                    (error)=>{
                        console.log(error);
                    });

            }
            else if(this.customerSignup.value.action == "generate_customer"){

                this.serverService.sendServer(customerCreate).then((response:any) => {
                 
                        if(response.result == 'true'){
                            console.log(response);
                            localStorage.setItem('access_token', response.cid);
                            this.customersignupSuccess = "Customer created successfully";
                            this.customersignupError="";
                            this.customerSignup.reset();
                            setTimeout(() => {this.router.navigate(['/bookings']); }, 3000);

                        }
                        else{
                             this.customersignupSuccess = "";
                             this.customersignupError="Invalid Credientials";

                        }

                    }, 
                    (error)=>{
                        console.log(error);
                    });

            }

    }
    
    viewSignIn(){
        
        this.signupData = false;
    }
    
    viewSignUp(){
        
        this.signupData = true;
    }
    
    onCustomerSingin(){
            
        let customerlogin:any = ({"api_type":"application","operation":"customer_mobile_verify","access_token":"","moduleType":"login","element_data":this.customerSignin.value});
            
            if(this.customerSignin.value.action_key == "generate_otp"){
                    this.serverService.sendServer(customerlogin).then((response:any) => {
                        if(response.result == "true"){
                            this.customerSignin.patchValue({
                                'action' : 'customers_login',
                                'action_key' : 'validate_otp',
                                'otp_token' : response.otp_token,
                            });

                             this.signinOtp = true;
                              this.customersigninSuccess="OTP Sucessfully Sent";
                             this.customersigninError="";

                        }
                        else{
                            this.customersigninSuccess = "";
                            this.customersigninError = "Invalid User Credientials";
                        }
                    }, 
                    (error)=>{
                        console.log(error);
                    });

            }
            else if(this.customerSignin.value.action_key == "validate_otp"){
            
                this.serverService.sendServer(customerlogin).then((response:any) => {
                if(response.result == 'true'){
                         localStorage.setItem('access_token', response.cid);
                            this.customersigninSuccess = "Customer Signed in successfully";
                            this.customersigninError="";
                    
                            this.customerSignin.reset();
                            setTimeout(() => {this.router.navigate(['/bookings']); }, 3000);

                        }
                        else{
                            this.customersigninSuccess = "";
                            this.customersigninError="Please Enter a Valid OTP";
                            

                        }
                    }, 
                    (error)=>{
                        console.log(error);
                    });

            }

    }



}
