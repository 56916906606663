import { Component, OnInit } from '@angular/core';
import { ServerService } from '../../services/server.service';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { $ } from 'protractor';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-booking-confirmed',
  templateUrl: './booking-confirmed.component.html',
  styleUrls: ['./booking-confirmed.component.css']
})
export class BookingConfirmedComponent implements OnInit {

  url ="https://call4drivers.com/adminpanel/api/paytmpost";
  accessToken:string;
  driver:string;
  booking_id:any;
  driver_id: any;
  ride_amount: any;
  booking_status: any;
  payment_status = 'not paid';
  public isSpinnerVisible = true;
  public isBookingconfirmed = true;
  public isPaid = false;
  cust_id :any;
  paymentForm: FormGroup;
  constructor(private serverService: ServerService,private router: Router) { }

  ngOnInit() {
    this.paymentForm = new FormGroup({
      business: new FormControl(null,Validators.required),
      cmd: new FormControl(null,Validators.required),
      booking_id: new FormControl(null,Validators.required),
      amount: new FormControl(null,Validators.required),
      customer_id: new FormControl(null,Validators.required),
      driver_id: new FormControl(null,Validators.required),
      return: new FormControl(null,Validators.required),
      cancel_return: new FormControl(null,Validators.required)
    });
    this.isBookingconfirmed = false;
    this.accessToken = localStorage.getItem('access_token');
    this.booking_id = localStorage.getItem('booking_id');
    this.driver_id = localStorage.getItem('driver_id');
    this.cust_id= atob(this.accessToken);
    if(this.booking_id){

    } else {
      this.router.navigate(['/bookings']);
    }
    this.getDriversAvail();
  }
  

  getDriversAvail(){
    if(this.driver_id){
      this.isSpinnerVisible = false;
      this.getDrivers();
    } else {
      this.isSpinnerVisible = true;
      setTimeout(() => {
          this.getDrivers();
      }, 5000);  //5s
    }
  }

getDrivers(){
  this.isBookingconfirmed = false;
  let booking_details:any = ({"api_type":"application","operation":"driver_details","access_token":this.accessToken,"booking_id":this.booking_id});
  this.serverService.sendServer(booking_details).then((response:any) => {
     if(response[0].result == "true"){
      this.driver = response[0];
      
      if(response[0].booking_status == '1'){
        this.booking_status = 'Booked';
        this.ride_amount = '--';
       }else if(response[0].booking_status == '2'){
        this.booking_status = 'In Ride';
        this.ride_amount = '--';
       } else if(response[0].booking_status == '6'){
        this.ride_amount = response[0].fee;
        this.booking_status = 'Dropped';
       }
        if(response[0].booking_status == '8'){
        this.ride_amount = response[0].fee;
        this.booking_status = 'completed';
       }
      localStorage.setItem('driver_id',response[0].driver_id);
      this.isSpinnerVisible = false;
      this.isBookingconfirmed = true;
      this.getstatu();
    } else {
      this.isBookingconfirmed = false;
      this.isSpinnerVisible = true;
      this.getDriversAvail();
    }
  });
}

getstatu(){
    if(this.booking_status == '8'){
      this.booking_status == 'Completed';
      this.payment_status = 'paid';
      localStorage.removeItem('booking_id');
      localStorage.removeItem('driver_id');
      Swal.fire({
        title: 'Trip Completed!',
       text:  'Thank You for travel with us.',
       icon: 'success'
     }).then((result) => {
       this.router.navigate(['/bookings']);
     });
    } else {
      setTimeout(() => {
          this.getBookingStatus();
      }, 5000);  //5s
    }
  }
cancelCustomer(book_id){
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, cancel it!'
  }).then((result) => {
    if (result.value) {
      let booking_details:any = ({"api_type":"application","operation":"cancel_booking","access_token":this.accessToken,"booking_id":book_id});
      this.serverService.sendServer(booking_details).then((response:any) => {
         if(response[0].result == "true"){
          Swal.fire({
             title: 'Cancelled!',
            text:  'Your booking has been Cancelled.',
            icon: 'success'
          }).then((result) => {
            localStorage.removeItem('booking_id');
            localStorage.removeItem('driver_id');
            this.router.navigate(['/bookings']);
          });
         }
        });
    }
  })
}

getBookingStatus(){
  let booking_details:any = ({"api_type":"application","operation":"booking_status_cust","access_token":this.accessToken,"booking_id":this.booking_id});
  this.serverService.sendServer(booking_details).then((response:any) => {
    console.log(response);
     if(response[0].result == "true"){
       this.booking_status = response[0].booking_status;
       if(response[0].booking_status == '1'){
        this.booking_status = 'Booked';
       }else if(response[0].booking_status == '2'){
        this.booking_status = 'In Ride';
       } else if(response[0].booking_status == '6'){
        this.ride_amount = response[0].net_amt;
        this.booking_status = 'Dropped';
       }
        this.getstatu();
    } else {
  
    }
  });
}

payOnline(){
  // this.driver_id = localStorage.getItem('driver_id');
  //  let transaction_details:any = ({"api_type":"application","operation":"transaction","access_token":this.accessToken,"booking_id":this.booking_id,"driver_id":this.driver_id});
  //  this.serverService.sendServer(transaction_details).subscribe((response:any) => {
  //    if(response[0].message=="true"){
  //       localStorage.removeItem('driver_id');
  //       localStorage.removeItem('booking_id');
  //       this.payment_status = 'paid';
  //       this.isPaid = true;
  //       setTimeout(() => {
  //         this.router.navigate(['/bookings']);
  //     }, 5000);  //5s
       
  //    }
  // });
}
}