import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServerService } from '../../services/server.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
	pageData:string;
	titleone:string;
	descriptionone:string;
	titletwo:string
	descriptiontwo:string;
	titlethree:string
	descriptionthree:string;

  constructor(private serverService: ServerService, private router:Router) { }

  ngOnInit() {
  
  }


}
