import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DateTimePickerModule } from '@syncfusion/ej2-angular-calendars';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  myDate: any;
  accessToken: any;
  customerBooking: FormGroup;
  public today: Date = new Date();
  public currentYear: number = this.today.getFullYear();
  public currentMonth: number = this.today.getMonth();
  public currentday: number = this.today.getDay();
  public minDate: Object = new Date(this.currentYear, this.currentMonth, this.currentday + 1);
  constructor(private router: Router) { }
  public format: string = 'dd-MMM-yy hh:mm a';
  ngOnInit() {
    this.accessToken = localStorage.getItem('access_token');
    this.customerBooking = new FormGroup({
      'booking_city': new FormControl('2'),
      'booking_type': new FormControl('1'),
      'booking_date': new FormControl(null)
    });
    console.log(this.currentday);
    console.log(this.currentMonth);
    console.log(this.currentYear);

  }

  // disabledDate(args): void {
  //   console.log(args.date);
  //   if (args.date.getMonth()) {
  //     //set 'true' to disable the weekends
  //     // console.log(args);
  //     args.isDisabled = true;
  //   }
  // }

  redirectBookings() {
    localStorage.setItem('booking_city', this.customerBooking.value.booking_city);
    localStorage.setItem('booking_type', this.customerBooking.value.booking_type);

    if (this.customerBooking.value.booking_date) {
      localStorage.setItem('booking_date', this.customerBooking.value.booking_date);
    } else {
      this.myDate = new Date(Date.now());
      localStorage.setItem('booking_date', this.myDate);
    }
    if (this.accessToken) {
      this.router.navigate(['/bookings']);
    } else {
      this.router.navigate(['/customers']);
    }

  }

  ngAfterViewInit() {





  }

}
