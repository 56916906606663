import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServerService } from '../../services/server.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class CustomerAccountComponent implements OnInit {
 prev_bookings:string;
 accessToken:string;

  constructor(private serverService: ServerService, private router:Router) { }

   ngOnInit() {

    this.accessToken = localStorage.getItem('access_token');
    if(this.accessToken != ''){
    this.getCustomerTravelInfo();
    }
    else{
      this.router.navigate(['/']);
    }
  }

  getCustomerTravelInfo(){

            let customerDetails:any = ({"api_type":"application","operation":"customer_bookingdetails","access_token":this.accessToken,"moduleType":"customers","element_data":[]});

               this.serverService.sendServer(customerDetails).then((response:any) => {
                        if(response.status == "true"){
                                this.prev_bookings = response.options;
                                console.log(this.prev_bookings);
                        }
                        else{
                            
                       
                        }
                    }, 
                    (error)=>{
                        console.log(error);
                    });


  }
}
