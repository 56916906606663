import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
//import * as $ from 'jquery';
import { Router } from '@angular/router';
import { MapsAPILoader, AgmCoreModule } from '@agm/core';
import { ServerService } from '../services/server.service';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { google } from '@google/maps';



declare let google: any;

@Component({
  selector: 'app-bookings',
  templateUrl: './bookings.component.html',
  styleUrls: ['./bookings.component.css']
})

export class BookingsComponent implements OnInit {


  @ViewChild('pickup_location') public pickupLocationElement: ElementRef;
  @ViewChild('drop_location') public dropLocationElement: ElementRef;
  @ViewChild('map') mapRef: ElementRef;
  fromLatitude: number;
  fromLongitude: number;
  fromPlace: string;
  toPlace: string;
  toLatitude: number;
  toLongitude: number;
  latitude: number;
  longitude: number;
  zoom: number;
  map: Object;
  marker: Object;
  bookingDetails: FormGroup;
  distance: any = "0";
  accessToken: string;
  booking_id: string;
  booking_type: any;
  booking_city: any;
  booking_date: any;
  public format: string = 'dd-MMM-yy hh:mm a';
  public dateValue: any;
  public today: Date = new Date();
  public currentYear: number = this.today.getFullYear();
  public currentMonth: number = this.today.getMonth();
  public currentday: number = this.today.getDay();
  public minDate: Object = new Date(this.currentYear, this.currentMonth, this.currentday + 1);

  constructor(private datePipe: DatePipe, private router: Router, private mapsAPILoader: MapsAPILoader, private ngZone: NgZone, private serverService: ServerService) {
  }

  ngOnInit() {
    this.accessToken = localStorage.getItem('access_token');
    this.booking_id = localStorage.getItem('booking_id');
    this.booking_type = localStorage.getItem('booking_type');
    this.booking_city = localStorage.getItem('booking_city');
    this.booking_date = localStorage.getItem('booking_date');

    this.dateValue = this.datePipe.transform(this.booking_date, 'dd-MMM-yy hh:mm a');
    if (this.accessToken == '') {
      this.router.navigate(['/']);
    }
    if (this.booking_id) {
      this.router.navigate(['/booking-confirmation']);
    }
    this.zoom = 13;
    this.latitude = 9.917037;
    this.longitude = 78.109445;
    this.mapData(this.latitude, this.longitude);
    this.updateBookingMarker();
    this.customerCurrentLocation();
    //                 var defaultBounds = new google.maps.LatLngBounds(
    // new google.maps.LatLng(9.9252, 78.1198),
    // );

    // var options = {
    // bounds: defaultBounds
    // };

    var defaultBounds = new google.maps.LatLngBounds(
      new google.maps.LatLng(9.9252, 78.1198));

    var options = {
      bounds: defaultBounds,
      types: ['establishment'],
      componentRestrictions: { country: 'ind' }
    };
    this.mapsAPILoader.load().then(() => {
      let pickupLocation = new google.maps.places.Autocomplete(this.pickupLocationElement.nativeElement, options);
      pickupLocation.addListener("place_changed", () => {
        this.ngZone.run(() => {
          let place: google.maps.places.PlaceResult = pickupLocation.getPlace();
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          this.fromLatitude = place.geometry.location.lat();
          this.fromLongitude = place.geometry.location.lng();
          this.fromPlace = place.formatted_address;
          this.latitude = this.fromLatitude;
          this.longitude = this.fromLongitude;
          this.zoom = 15;
          this.distanceCalculator();
          this.mapData(this.latitude, this.longitude);
          this.updateBookingMarker();


        });
      });

      let dropLocation = new google.maps.places.Autocomplete(this.dropLocationElement.nativeElement);
      dropLocation.addListener("place_changed", () => {
        this.ngZone.run(() => {
          let place: google.maps.places.PlaceResult = dropLocation.getPlace();

          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          this.toLatitude = place.geometry.location.lat();
          this.toPlace = place.formatted_address;
          this.toLongitude = place.geometry.location.lng();
          this.zoom = 15;
          this.distanceCalculator();
          this.mapData(this.toLatitude, this.toLongitude);
          this.updateBookingMarker();


        });
      });


    });

    this.bookingDetails = new FormGroup({
      'booking_from': new FormControl(null, Validators.required),
      'booking_to': new FormControl(null, Validators.required),
      'booking_date': new FormControl(this.dateValue),

    });


  }

  distanceCalculator() {
    if (this.fromLatitude != null && this.toLatitude != null) {
      this.distance = google.maps.geometry.spherical.computeDistanceBetween(new google.maps.LatLng(this.fromLatitude, this.fromLongitude), new google.maps.LatLng(this.toLatitude, this.toLongitude));
      this.distance = (this.distance / 1000).toFixed(1);
    }
    else {
      this.distance = "0";
    }

  }


  getActiveDrivers() {
    var activeDriversList: any;
    activeDriversList = '[{"driver_name":"kn","lat":9.92,"lon":78.12},{"driver_name":"pn","lat":9.93,"lon":78.11},{"driver_name":"rt","lat":9.83,"lon":78.06},{"driver_name":"jn","lat":9.92,"lon":78.14}]';
    activeDriversList = JSON.parse(activeDriversList);
    for (let drivers_list of activeDriversList) {
      this.addMarker(drivers_list.lat, drivers_list.lon, "diver");
    }
  }

  calculateAndDisplayRoute(directionsService, directionsDisplay) {
    if (this.fromLatitude != null && this.toLatitude != null) {
      directionsService.route({
        origin: { lat: this.fromLatitude, lng: this.fromLongitude },
        destination: { lat: this.toLatitude, lng: this.toLongitude },
        travelMode: 'DRIVING'
      }, function (response, status) {
        if (status === 'OK') {
          directionsDisplay.setDirections(response);
        }
        else {
          console.log('Directions request failed due to ' + status);
        }
      });
    }

  }

  onbookingDetails() {
    var origin: any = { lat: this.fromLatitude, lng: this.fromLongitude };
    var destination: any = { lat: this.toLatitude, lng: this.toLongitude };
    this.booking_date = this.datePipe.transform(this.bookingDetails.value.booking_date, 'dd-MMM-yy hh:mm a')

    if (this.fromLatitude == undefined) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please Enter Pickup Location',
      });
      return false;
    }
    if (this.toLatitude == undefined) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please Enter To Destination Location',
      });
      return false;
    }

    let getDrivers: any = ({ "api_type": "application", "operation": "get_near_driver", "access_token": this.accessToken, "origin": origin, "destination": destination, "from": this.fromPlace, "to": this.toPlace, "diatance": this.distance, "booking_type": this.booking_type, "booking_date": this.booking_date });
    this.serverService.sendServer(getDrivers).then((response: any) => {
      console.log(response);
      if (response.result == "true") {
        localStorage.setItem('booking_id', response.booking_id);
        this.router.navigate(['/booking-confirmation']);
      } else {
        Swal.fire('Sorry No Drivers')
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Sorry No Drivers Available At This Moment, Please Check Later',
        });
      }
    });
    // var activeDriversList:any;
    // activeDriversList = '[{"driver_name":"kn","lat":9.92,"lon":78.12},{"driver_name":"pn","lat":9.93,"lon":78.11},{"driver_name":"rt","lat":9.83,"lon":78.06},{"driver_name":"jn","lat":9.92,"lon":78.14}]';
    // activeDriversList = JSON.parse(activeDriversList);
    // console.log(activeDriversList);
    // console.log(origin);
    // console.log(destination);
    //this.getActiveDrivers();
  }

  bookigType(type) {
    localStorage.setItem('booking_type', type);
    this.booking_type = type;
  }

  updateBookingMarker() {
    if (this.latitude != undefined && this.longitude != undefined) {
      this.addMarker(this.latitude, this.longitude, "pickup");
    }

    if (this.toLatitude != undefined && this.toLongitude != undefined) {
      this.addMarker(this.toLatitude, this.toLongitude, "drop");
    }
  }

  addMarker(latitude, longitude, icon_type) {
    let marker_icon;
    if (icon_type == "pickup") {
      marker_icon = "assets/images/marker_pickup.png";
    }
    else if (icon_type == "drop") {
      marker_icon = "assets/images/marker_drop.png";
    }
    else if (icon_type == "diver") {
      marker_icon = "assets/images/marker_driver.png";
    }

    this.marker = new google.maps.Marker({
      position: new google.maps.LatLng(latitude, longitude),
      icon: marker_icon,
      map: this.map
    });

  }

  mapData(latitude, longitude) {

    var directionsService = new google.maps.DirectionsService;
    var directionsDisplay = new google.maps.DirectionsRenderer({ polylineOptions: { strokeColor: "#FFC61A" } });
    this.map = new google.maps.Map(this.mapRef.nativeElement, {
      zoom: this.zoom,
      disableDefaultUI: true,
      mapTypeId: google.maps.MapTypeId.TERRAIN,
      center: new google.maps.LatLng(latitude, longitude)
    });

    directionsDisplay.setMap(this.map);
    directionsDisplay.setOptions({ suppressMarkers: true });

    this.calculateAndDisplayRoute(directionsService, directionsDisplay);

  }

  customerCurrentLocation() {
    navigator.geolocation.getCurrentPosition((loc) => {
      this.latitude = loc.coords.latitude;
      this.longitude = loc.coords.longitude;
      this.zoom = 15;
      this.mapData(this.latitude, this.longitude);
      this.updateBookingMarker();
    });

  }

  ngAfterViewInit() {


  }

}
