import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServerService } from '../services/server.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
 accessToken:string;
 logginAs:string;
  constructor(private serverService: ServerService, private router:Router) { }

  ngOnInit() {

  	 		this.accessToken = localStorage.getItem('access_token');
  	 		this.logginAs = localStorage.getItem('logged_in');
             
  }

logOut(){

	localStorage.clear();
	this.router.navigate(['/']);
}

}
