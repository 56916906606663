import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable()
export class ServerService {
    
    constructor(private http:HttpClient)
    {
    }
    
    // sendServer(postData:any[]) {
    //     const httpOptions = {
    //       headers: new HttpHeaders({
    //         'Content-Type':  'application/json'
    //       })
    //     };
    //     return this.http.post("https://call4drivers.com/api/", postData,httpOptions);
    // }

    sendServer(postData:any[]) {
        
      // const httpOptions = {
      //   headers: new HttpHeaders({
      //     'Content-Type':  'application/json'
      //   })
      // };
      
      // return this.http.post("", postData,httpOptions);
      return new Promise((resolve, reject) => {
        let headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');

        this.http.post('https://call4drivers.com/api/', JSON.stringify(postData), {headers: headers})
          .subscribe(res => {
            resolve(res);
          }, (err) => {
            reject(err);
          });
    });
  }
}