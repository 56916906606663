import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { DateTimePickerModule } from '@syncfusion/ej2-angular-calendars';
import {DatePipe} from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule }  from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { AgmCoreModule } from '@agm/core';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { AppComponent } from './app.component';
import { PagesComponent } from './pages/pages.component';
import { AboutComponent } from './pages/about/about.component';
import { DriversComponent } from './drivers/drivers.component';
import { CustomersComponent } from './customers/customers.component';
import { MenuComponent } from './menu/menu.component';
import { HomeComponent } from './home/home.component';
import { CustomerProfileComponent } from './customers/profile/profile.component';
import { CustomerAccountComponent } from './customers/account/account.component';
import { BookingsComponent } from './bookings/bookings.component';
import { OurPricingComponent } from './pages/our-pricing/our-pricing.component';
import { HowItWorksComponent } from './pages/how-it-works/how-it-works.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { TermsAndConditionsComponent } from './pages/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { OurServicesComponent } from './pages/our-services/our-services.component';
import { CitiesComponent } from './pages/cities/cities.component';
import { DriverComponent } from './drivers/driver/driver.component';
import { AgentComponent } from './drivers/agent/agent.component';
import { DriverProfileComponent } from './drivers/driver-profile/driver-profile.component';
import { DatetimePopupModule } from 'ngx-bootstrap-datetime-popup';
import { ServerService } from './services/server.service';
import { BookingConfirmedComponent } from './bookings/booking-confirmed/booking-confirmed.component';
import { PaymentComponent } from './bookings/payment/payment.component';

const appRoutes: Routes = [
    {
        path :'', component:HomeComponent
    },{
        path :'home', component:HomeComponent
    },{
        path :'about-us', component:AboutComponent
    },{
        path :'bookings', component:BookingsComponent
    },{
        path :'agents', component:AgentComponent
    },{
        path :'customers', component:CustomersComponent
    },{
        path :'drivers', component:DriverComponent
    },{
        path :'our-services', component:OurServicesComponent
    },{
        path :'terms-conditions', component:TermsAndConditionsComponent
    },{
        path :'contact-us', component:ContactUsComponent
    },{
        path :'customer-profile', component:CustomerProfileComponent
    }
    ,{
        path :'driver-profile', component:DriverProfileComponent
    },{
        path :'cities', component:CitiesComponent
    },{
        path :'how-it-works', component:HowItWorksComponent
    },
    {
        path :'privacy-policy', component:PrivacyPolicyComponent
    },
    {
        path :'pricing', component:OurPricingComponent
    },
       {
        path :'my-account', component:CustomerAccountComponent
    },{
        path :'booking-confirmation', component:BookingConfirmedComponent
    },
    ]

@NgModule({
  declarations: [
    AppComponent,
    PagesComponent,
    AboutComponent,
    DriversComponent,
    CustomersComponent,
    MenuComponent,
    HomeComponent,
    CustomerProfileComponent,
    CustomerAccountComponent,
    BookingsComponent,
    OurPricingComponent,
    HowItWorksComponent,
    ContactUsComponent,
    TermsAndConditionsComponent,
    PrivacyPolicyComponent,
    OurServicesComponent,
    CitiesComponent,
    DriverComponent,
    AgentComponent,
    DriverProfileComponent,
    BookingConfirmedComponent,
    PaymentComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,DateTimePickerModule,
    RouterModule.forRoot(appRoutes),
    AgmCoreModule.forRoot({
     apiKey: 'AIzaSyC3HA3hAHm6YBNz7iOb3c7aa_PRp8XBqto',
     libraries: ["geometry,places"]
     })
  ],
  providers: [ServerService,DatePipe, {provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
